<template>
  <v-container v-if="vueTocs">
    <v-row>
      <v-col cols="12">
        <PageToolbar title="Tocs" icon="settings" dark />
      </v-col>

      <v-col cols="12">
        <ListaTocs />
      </v-col>
    </v-row>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tocs",
  components: {
    ListaTocs: () => import("./components/ListaTocs"),
  },
  props: {},
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueTocs() {
      return this.getAccess("vueTocs", "menu");
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
